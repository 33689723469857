import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';

import imgDownPay from '../../assets/images/payment-plan/down payment.svg';
import imgFirstInst from '../../assets/images/payment-plan/First instalment.svg';
import imgFinalInst from '../../assets/images/payment-plan/Final instalment.svg';
import imgPhoto from '../../assets/images/payment-plan/Photo 2 (3).png';
import imgDots from '../../assets/images/payment-plan/Dots 2 (1).svg';
// import imgSinglePay from '../../assets/images/payment-plan/One single payment.svg';
// import imgTwoPay from '../../assets/images/payment-plan/Two payment.svg';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import '../../assets/styles/pages/payment-plan-page.scss';

const PaymentPlan = () => {
  return (
    <Layout
      title={{ id: 'paymentPlan.seo.title' }}
      description={{ id: 'paymentPlan.seo.description' }}
    >
      <div className="payment-plan-page-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="paymentPlan.main.title" />
            </h1>
          </div>
        </section>
        <section className="second-section c-section-py">
          <div className="container top">
            <div className="row">
              <div className="col-md-5 col-12">
                <h2 className="title">
                  <FormattedMessage id="paymentPlan.sec1.title" />
                </h2>
              </div>
              <div className="col-md-7 col-12">
                <p className="description">
                  <FormattedMessage id="paymentPlan.sec1.description" />
                </p>
              </div>
            </div>
          </div>
          <div className="container bottom">
            <h2 className="title">
              <FormattedMessage id="paymentPlan.sec1.bot.title" />{' '}
            </h2>
            <p className="description">
              <FormattedMessage id="paymentPlan.sec1.bot.description" />
            </p>
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <img src={imgDownPay} className="item__img" alt="Down payment" />
                  <h3 className="item__title">
                    <FormattedMessage id="paymentPlan.sec1.ben1.title" />
                  </h3>
                  <p className="item__percentage">450€</p>
                  <p className="item__subtitle">
                    <FormattedMessage id="paymentPlan.sec1.ben1.text" />
                  </p>
                  {/* <p className="item__description">
                    For example, on the standard TEFL package (1900€) you would pay:
                  </p>
                  <p className="item__price">760€</p> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <img src={imgFirstInst} className="item__img" alt="First instalment" />
                  <h3 className="item__title">
                    <FormattedMessage id="paymentPlan.sec1.ben2.title" />
                  </h3>
                  <p className="item__percentage">600€</p>
                  <p className="item__subtitle">
                    <FormattedMessage id="paymentPlan.sec1.ben2.text" />
                  </p>
                  {/* <p className="item__description">
                    For example, on the standard TEFL package (1900€) you would pay:
                  </p>
                  <p className="item__price">760€</p> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <img src={imgFinalInst} className="item__img" alt="Final instalmen" />
                  <h3 className="item__title">
                    <FormattedMessage id="paymentPlan.sec1.ben3.title" />
                  </h3>
                  <p className="item__percentage">600€</p>
                  <p className="item__subtitle">
                    {' '}
                    <FormattedMessage id="paymentPlan.sec1.ben3.text" />
                  </p>
                  {/* <p className="item__description">
                    For example, on the standard TEFL package (1900€) you would pay:
                  </p>
                  <p className="item__price">380€</p> */}
                </div>
              </div>
            </div>
            <p className="bottom__text">
              <FormattedMessage id="paymentPlan.sec1.bot.text" />
            </p>
          </div>
        </section>

        {/* <section className="third-section c-section-py">
          <div className="container">
            <h2 className="title c-title-34 ">
              Blended CertTESOL Course <span className="colored no-link">Payment</span>
            </h2>
            <div className="row">
              <div className="col-xl-6 col-12">
                <div className="item">
                  <div className="item__top">
                    <h3 className="item__title">One single payment</h3>
                    <img src={imgSinglePay} alt="" className="item__img" />
                  </div>
                  <div className="item__payment">
                    <h4 className="payment__title">1300€</h4>
                    <p className="payment__description">(save 100€)</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="item">
                  <div className="item__top">
                    <h3 className="item__title">Two payments</h3>
                    <img src={imgTwoPay} alt="" className="item__img" />
                  </div>
                  <div className="item__payment">
                    <h4 className="payment__title">700€</h4>
                    <p className="payment__description">to start the online course</p>
                  </div>
                  <div className="item__payment plus">
                    <h4 className="payment__title">700€</h4>
                    <p className="payment__description">1 month before your course date</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="fourth-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    <FormattedMessage id="paymentPlan.sec2.title" />
                  </h2>
                  <p className="block_l__description c-text-18">
                    <FormattedMessage id="paymentPlan.sec2.description" />
                  </p>
                  <h3 className="block_l__subtitle">
                    <FormattedMessage id="paymentPlan.sec2.conditions" />
                  </h3>
                  <p className="block_l__terms">
                    {/* Please take a moment to read the{' '}
                    <Link to="/apply/terms" className="red-link">
                      payment terms & conditions.
                    </Link> */}
                    <FormattedMessage
                      id="paymentPlan.sec2.condText"
                      values={{
                        accentedLink: (chunk) => (
                          <FormattedMessage id="url.apply.terms">
                            {(locale) => (
                              <Link to={locale[0]} className="red-link">
                                {chunk}
                              </Link>
                            )}
                          </FormattedMessage>
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img
                    src={imgPhoto}
                    alt="TEFL & Student Visa / Spanish"
                    className="block_r__img"
                  />
                  <img src={imgDots} alt="dots" className="dots" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default PaymentPlan;
